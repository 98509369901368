<template>
    <div class="page-wrapper">
        <div class="header">
            <img src="../assets/logo.png" @click="Home"/>
            <a v-if="!isAdmin" @click="Cart"><i class="fa fa-shopping-cart"></i></a>
            <a v-if="isAdmin" @click="addProducts" class="ml-auto" ><i class="fa fa-shopping-bag"></i></a>
            <a v-if="isAdmin" @click="Orders" class="ml-3"><i class="fa fa-truck"></i></a>
        </div>
        <div class="page-content">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>
        <div class="bg-dark footer">
            Powered by Xeoscript Technologies
        </div>
    </div>
</template>

<script>
import getMenu from '../data/menu';
import { mapGetters } from 'vuex';

export default {
    components: {},
    data () {
        return {
            menu: getMenu()
        };
    },
    computed: {
        ...mapGetters(['currentUser']),
        isAdmin () {
            const currentUser = this.currentUser;
            return currentUser && currentUser.role === 'Shop Admin';
        }
    },
    methods: {
        Cart () {
            this.$router.push({ path: '/cart/' });
        },
        Orders () {
            this.$router.push({ path: '/admin/orders/' });
        },
        addProducts () {
            this.$router.push({ path: '/admin/product-add/' });
        },
        Home () {
            if (this.isAdmin) {
                this.$router.push({ path: '/admin/catalog/' });
            } else {
                this.$router.push({ path: '/' });
            }
        }
    },
    metaInfo: {
        titleTemplate: '%s - Nila Bake House',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>
